<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="500px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        class="upload_box">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules"   label-width="100px">

                <el-form-item label="名字" prop="name">
                    <el-input  v-model="ruleForm.name"  />
                </el-form-item>


                <el-form-item label="原价(分)" prop="originalPrice">
                    <el-input  v-model="ruleForm.originalPrice"  />
                </el-form-item>

                <el-form-item label="现价(分)" prop="realPrice">
                    <el-input  v-model="ruleForm.realPrice"  />
                </el-form-item>

                <el-form-item label="字数限制" prop="wordNumLimit">
                    <el-input  v-model="ruleForm.wordNumLimit"  />
                </el-form-item>

                <el-form-item label="月数" prop="months">
                    <el-input  v-model="ruleForm.months"  />
                </el-form-item>
                

                <el-form-item label="是否启用" prop="status">
                    <el-select v-model="ruleForm.status" style="width:100%" placeholder="请选择">
                        <el-option label="启用" :value="1" />
                        <el-option label="禁用" :value="2" />
                    </el-select>
                </el-form-item>

                <el-form-item label="排序" prop="sort">
                    <el-input  v-model="ruleForm.sort"  />
                </el-form-item>




            </el-form>
        <!-- 表单结束 -->
        <!--操作按钮-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary"  @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
  
  </template>
  
  <script>
  // 引入组件及方法
  // 引入组件
  export default {
    components: {  },
    // 数据
    data() {
      return {
        ruleForm:{
            giftAmount:0,
            name:"",
            originalPrice:0,
            realPrice:0,
            wordNumLimit:0,
            months:0,
            status:1,//状态：1 启用，2 禁用
            sort:0
        },
        rules: {
            giftAmount: [{ required: true, message: '请输入', trigger: 'change' }],
            name: [{ required: true, message: '请输入', trigger: 'change' }],
            originalPrice: [{ required: true, message: '请输入', trigger: 'change' }],
            realPrice: [{ required: true, message: '请输入', trigger: 'change' }],
            wordNumLimit: [{ required: true, message: '请输入', trigger: 'change' }],
            months: [{ required: true, message: '请输入', trigger: 'change' }],
            sort: [{ required: true, message: '请输入', trigger: 'change' }]
        },
        isEdit:false,
        proId:""
      }
    },
    props:{
        visible:Boolean
    },
    watch: {
        visible(){
            if(this.visible){




            }
        }
    },
    //  初始化
    mounted() {
      
    },
  
    methods: {

        start(obj, id){
            if(obj){
                this.ruleForm = obj
                this.isEdit = true
                this.proId = id

            }else{
                this.isEdit = false
                this.ruleForm.giftAmount = 0
                this.ruleForm.name = ''
                this.ruleForm.originalPrice = 0
                this.ruleForm.realPrice = 0
                this.ruleForm.status = 1
                this.ruleForm.wordNumLimit = 0
                this.ruleForm.months = 1
                this.ruleForm.sort = 0

                
            }
        },
        handleClose(){
            this.$emit('update:visible', false)
        },
        handleSubmit(){

            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    

                    var param = {
                        goodsTrainingText:{
                            giftAmount:this.ruleForm.giftAmount,
                            name:this.ruleForm.name,
                            originalPrice:this.ruleForm.originalPrice,
                            realPrice:this.ruleForm.realPrice,
                            wordNumLimit:this.ruleForm.wordNumLimit,
                            months:this.ruleForm.months,
                        },
                        goodsType:1,
                        status:this.ruleForm.status,
                        sort:this.ruleForm.sort
                    }

                    if(this.isEdit){
                        param.id = this.proId

                        this.$https('updateGoods', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }else{

                        this.$https('addGoods', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                    


                    
                } else {
                    
                }
            });
            


        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  

  
  </style>
  